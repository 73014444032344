import { Button } from "@aws-amplify/ui-react";
import { ContentCopy } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { DataStore } from "aws-amplify";
import React, { InputHTMLAttributes, useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  Client,
  GithubToken,
  LazyGithubToken,
  Status,
  TokenStatus,
} from "../models";
import ClientCreate from "./ClientCreate";
import ClientEdit from "./ClientEdit";
import { DOCS_DOMAIN } from "./utils/docs";

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

const columnHelper = createColumnHelper<Client>();
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value, {
    threshold: 3,
  });

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const CopyLinkButton = ({
  url,
  children,
}: {
  children: React.ReactNode;
  url: string;
}) => {
  return (
    <div className="flex justify-center align-middle">
      <a
        className="px-2 text-main-blue hover:text-blue-700"
        rel="noreferrer"
        target="_blank"
        href={url}
      >
        {children}
      </a>
      <span
        className="cursor-pointer text-main-blue hover:text-blue-700"
        onClick={() => {
          navigator.clipboard.writeText(url);
        }}
      >
        <ContentCopy fontSize="small" className="h-[10px] m-0 p-0 pb-0" />
      </span>
    </div>
  );
};

const columns = [
  columnHelper.accessor("name", {
    id: "name",
    header: () => "Client Name",
    cell: (info) => info.getValue(),
    sortingFn: "text",
  }),
  columnHelper.accessor(
    (row) => (row.useScaleOpsToken ? row.scaleOpsToken : row.githubToken),
    {
      id: "token",
      header: () => "Token",
      cell: (info) => info.getValue(),
    },
  ),
  columnHelper.accessor((row) => (row.status ? row.status : Status.PREMIUM), {
    id: "status",
    header: () => "Status",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("url", {
    header: () => "URLs",
    cell: (info) => {
      let grafana_host = "scaleops.grafana.net";
      if (info.row.getValue("firstClusterRegion") === "us") {
        grafana_host = "usscaleops.grafana.net";
      }
      return (
        <div className="justify-between">
          {/* uncomment to add workspace link to the table */}
          {/*{info.row.original.subdomain ? (*/}
          {/*    <CopyLinkButton*/}
          {/*        url={`https://${info.row.original.subdomain}.scaleops.com`}*/}
          {/*    >*/}
          {/*        Workspace*/}
          {/*    </CopyLinkButton>*/}
          {/*) : null}*/}
          <CopyLinkButton url={window.location.origin + "/" + info.getValue()}>
            Install Page
          </CopyLinkButton>
          <CopyLinkButton
            url={`https://${DOCS_DOMAIN}/?token=${info.row.getValue("token")}`}
          >
            Docs
          </CopyLinkButton>
          <CopyLinkButton
            url={`https://${grafana_host}/d/e7b2bc4d-dcab-42d3-85ed-8c3cf2264b27/business-customer-view?orgId=1&var-customer=${info.row.getValue("name")}&from=now-2d&to=now&refresh=5m&var-token=${info.row.getValue("token")}&var-Filters=&var-interval_seconds=60`}
          >
            Grafana
          </CopyLinkButton>
        </div>
      );
    },
    enableSorting: false,
  }),
  columnHelper.accessor("createdAt", {
    header: () => "Created At",
    cell: (info) => info.getValue(),
    sortingFn: "datetime",
  }),
  columnHelper.accessor("updatedAt", {
    header: () => "Updated At",
    cell: (info) => info.getValue(),
    sortingFn: "datetime",
  }),
];

type comperableList = {
  name: string;
};

function listsAreEquel<T extends comperableList>(a: T[], b: T[]): boolean {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => {
      return val.name === b[index].name;
    })
  );
}

const RowClassBase = "border border-gray-500 hover:bg-opacity-50";

const ClientsTableV2 = () => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [data, setData] = useState<Client[]>([]);
  const [tokenData, setTokenData] = useState<GithubToken[]>([]);

  const [selectedClient, setSelectedClient] = useState<Client | undefined>(
    undefined,
  );
  const [createClient, setCreateClient] = useState<boolean>(false);
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "name",
      desc: false,
    },
  ]);

  const getClientToken = (client: Client): string => {
    return client.useScaleOpsToken && client.scaleOpsToken
      ? client.scaleOpsToken
      : client.githubToken;
  };

  useEffect(() => {
    console.log("Sorting changed", sorting);
  }, [sorting]);
  useEffect(() => {
    const watcher = DataStore.observeQuery(Client).subscribe((snapshot) => {
      const { items, isSynced } = snapshot;
      console.log(
        `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`,
      );
      if (isSynced) {
        const newData = items
          .sort((a, b) => {
            return getClientToken(a)
              .toLowerCase()
              .trim()
              .localeCompare(getClientToken(b).toLowerCase().trim());
          })
          .map((item) => {
            return Client.copyOf(item, (c) => {
              c.name = item.name.trim();
              c.status = item.status ?? Status.PREMIUM;
            });
          });
        if (!listsAreEquel(data, newData)) {
          setData(newData);
        }
      }
    });
    return () => {
      watcher.unsubscribe();
    };
  }, [data]);

  useEffect(() => {
    const watcher = DataStore.observeQuery(GithubToken).subscribe(
      (snapshot) => {
        const { items, isSynced } = snapshot;
        console.log(
          `[Snapshot] token item count: ${items.length}, isSynced: ${isSynced}`,
        );
        if (isSynced) {
          const newData = items
            .sort((a, b) => {
              return a.token
                .toLowerCase()
                .trim()
                .localeCompare(b.token.toLowerCase().trim());
            })
            .map((item) => {
              return GithubToken.copyOf(item, (t) => {
                t.name = item.name.trim();
              });
            });
          if (!listsAreEquel(tokenData, newData)) {
            setTokenData(newData);
          }
        }
      },
    );
    return () => {
      watcher.unsubscribe();
    };
  }, [tokenData]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
      sorting,
    },
    initialState: {
      sorting: [
        {
          id: "name",
          desc: false, // sort by name in descending order by default
        },
      ],
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  const getToken = (clientRow: Row<Client>): LazyGithubToken | undefined => {
    return tokenData.find((t) => {
      return t.token === clientRow.original.githubToken;
    });
  };

  const getRowClass = (token: LazyGithubToken | undefined) => {
    if (token !== undefined) {
      switch (token.tokenStatus) {
        case TokenStatus.TO_BE_DELETED:
          return RowClassBase + " bg-amber-100";
        case TokenStatus.ACTIVE:
          return RowClassBase;
        case TokenStatus.DELETED:
          return RowClassBase + " bg-red-100";
        default:
          return RowClassBase;
      }
    }
  };

  return (
    <div className="p-2">
      <DebouncedInput
        value={globalFilter ?? ""}
        onChange={(value) => setGlobalFilter(String(value))}
        className="p-2 m-0 my-2 font-lg border rounded border-block border-gray-400 w-full"
        placeholder="Search all columns..."
      />
      <table className="w-full">
        <thead className="bg-gray-200">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="py-3 px-2 border border-black">
                  {header.isPlaceholder ? null : (
                    <div
                      className={
                        header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : ""
                      }
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                              ? "Sort descending"
                              : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: " 🔼",
                        desc: " 🔽",
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
              <th className="py-3 px-2 border border-black">Edit</th>
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            let currToken = getToken(row);
            const rowClass = getRowClass(currToken);
            return (
              <tr key={row.id} className={rowClass}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="py-3 px-2 border border-black">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
                <td className="py-3 px-2 border border-black">
                  <Button
                    isDisabled={
                      currToken !== undefined &&
                      currToken.tokenStatus === TokenStatus.TO_BE_DELETED
                    }
                    variation="menu"
                    size="small"
                    color="grey"
                    onClick={() => setSelectedClient(row.original)}
                    ariaLabel="Edit Client"
                  >
                    <EditIcon />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ClientEdit
        onClose={() => {
          setSelectedClient(undefined);
        }}
        client={selectedClient}
      />
      <ClientCreate
        onClose={() => {
          setCreateClient(false);
        }}
        open={createClient}
      />
    </div>
  );
};

export default ClientsTableV2;
